/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./theme.css";
import "./global.css";
//import App from './App';

import {
  Button,
  Text,
  Heading,
  MultipleChoice,
} from "@taskalliance/task-component-library";

import {
  HashRouter,
  Link,
  Outlet,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<StartPage />} />
          <Route path="articles" element={<Articles />}>
            <Route path=":articleId" element={<Article />} />
          </Route>
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function Main() {
  return (
    <>
      <main>
        <Header />
        <Outlet />
        <Footer nextPath="articles/1234" />
      </main>
      <Aside />
    </>
  );
}

function StartPage() {
  return (
    <article>
      <p>Startsiden</p>
      <p>
        <Link to="articles/1234">Side 1234</Link>
      </p>
    </article>
  );
}
function Header() {
  return (
    <header>
      <Heading as="h1" size="small">
        Tittel/tema
      </Heading>
      <Button
        type={"button"}
        variant={"ghost"}
        onClick={() => {
          document.querySelector("#root>aside")?.classList.add("open");
        }}
      >
        <svg
          viewBox="0 0 100 75"
          width="40"
          height="40"
          role="presentation"
          focusable="false"
          aria-label="Open menu"
        >
          <rect width="100" height="15" rx="8"></rect>
          <rect y="30" width="100" height="15" rx="8"></rect>
          <rect y="60" width="100" height="15" rx="8"></rect>
        </svg>
      </Button>
    </header>
  );
}
function Footer({ nextPath = "" }) {
  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    console.log("Current location is", location);
  }, [location]);

  return (
    <footer>
      {location.pathname !== "/" ? (
        <Button
          type={"button"}
          variant={"ghost"}
          onClick={() => {
            navigate(-1);
          }}
        >
          &lt;
        </Button>
      ) : (
        <Button type={"button"} variant={"ghost"} disabled={true}>
          &lt;
        </Button>
      )}
      ......{" "}
      <Button
        type={"button"}
        variant={"ghost"}
        onClick={() => {
          navigate(nextPath);
        }}
      >
        &gt;
      </Button>
    </footer>
  );
}

function Articles() {
  return (
    <article>
      <Outlet />
    </article>
  );
}
function Article() {
  let { articleId } = useParams();
  return (
    <>
      <Text>Prod id: {articleId}</Text>
      <MultipleChoice
        question={<Heading as="h3">Hvor mye er 2 pluss to?</Heading>}
      >
        Bob
      </MultipleChoice>
      <Text style={{backgroundColor: "#0f0"}}>Bob bo ob obbbb</Text>
      <Button
        type={"button"}
        disabled={false}
        className={"ghost"}
        onClick={() => {
          alert("bobob!");
        }}
      >
        KNappeti og <Heading as="h1">Text inni der</Heading>
      </Button>
      <Text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Aliquet nibh
        praesent tristique magna sit. In massa tempor nec feugiat nisl pretium
        fusce id. Amet dictum sit amet justo donec enim. Ullamcorper a lacus
        vestibulum sed arcu non odio. Facilisi cras fermentum odio eu feugiat
        pretium nibh ipsum consequat. Lectus urna duis convallis convallis.
        Magna eget est lorem ipsum. Justo eget magna fermentum iaculis eu non
        diam phasellus vestibulum. Feugiat in fermentum posuere urna nec
        tincidunt praesent semper feugiat. Et molestie ac feugiat sed lectus.
        Sed adipiscing diam donec adipiscing tristique risus nec feugiat in.
        Viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Arcu
        dictum varius duis at consectetur lorem. In ornare quam viverra orci
        sagittis eu. Eleifend mi in nulla posuere sollicitudin aliquam ultrices
        sagittis. Pharetra magna ac placerat vestibulum. Cras ornare arcu dui
        vivamus arcu. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
        vitae. Nec nam aliquam sem et tortor consequat.
      </Text>
      <Text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Aliquet nibh
        praesent tristique magna sit. In massa tempor nec feugiat nisl pretium
        fusce id. Amet dictum sit amet justo donec enim. Ullamcorper a lacus
        vestibulum sed arcu non odio. Facilisi cras fermentum odio eu feugiat
        pretium nibh ipsum consequat. Lectus urna duis convallis convallis.
        Magna eget est lorem ipsum. Justo eget magna fermentum iaculis eu non
        diam phasellus vestibulum. Feugiat in fermentum posuere urna nec
        tincidunt praesent semper feugiat. Et molestie ac feugiat sed lectus.
        Sed adipiscing diam donec adipiscing tristique risus nec feugiat in.
        Viverra ipsum nunc aliquet bibendum enim facilisis gravida neque. Arcu
        dictum varius duis at consectetur lorem. In ornare quam viverra orci
        sagittis eu. Eleifend mi in nulla posuere sollicitudin aliquam ultrices
        sagittis. Pharetra magna ac placerat vestibulum. Cras ornare arcu dui
        vivamus arcu. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus
        vitae. Nec nam aliquam sem et tortor consequat.
      </Text>
    </>
  );
}

function Aside() {
  return (
    <aside id="sidenav-open">
      <a
        href='javascript:document.querySelector("#root>aside")?.classList.remove("open")'
        title="Close Menu"
        aria-label="Close Menu"
        id="sidenav-close"
      ></a>
      <nav>
        <p style={{ textAlign: "right", marginRight: "-3rem" }}>
          <Button
            type={"button"}
            variant={"ghost"}
            onClick={() => {
              document.querySelector("#root>aside")?.classList.remove("open");
            }}
          >
            <svg
              viewBox="0 0 10 10"
              width="40"
              height="40"
              role="presentation"
              focusable="false"
              aria-label="Close menu"
            >
              <path d="M2,2 L8,8" />
              <path d="M2,8 L8,2" />
            </svg>
          </Button>
        </p>
        <h4>Meny</h4>
        <a href="#">Til hovedmenyen</a>
        <a href="#">Avslutt</a>
        <a href="#">Logg ut</a>
      </nav>
    </aside>
  );
}

export default Main;
